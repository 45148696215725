:host {
  display: block;
  min-height: 1px; // Safari bug workaround
}

.divider {
  position: relative;
  padding: var(--spacing) 0;
}

.line__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.line {
  width: 100%;
  border-top: 1px solid var(--sc-divider-border-top-color, var(--sc-color-gray-200));
}

.text__container {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: var(--sc-font-size-small);
}

.text {
  padding: 0 var(--sc-spacing-small);
  background: var(--sc-divider-text-background-color, var(--sc-color-white));
  color: var(--sc-color-gray-500);
}
